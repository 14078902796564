import CryptoJS from "crypto-js";

const SECRET_KEY = "dodo_admin@@john_emil";

const StorageBox = {
  getToken: (): string | null => {
    try {
      const encryptedData: any = localStorage.getItem("admin-token");
      if (encryptedData) {
        const { token, expiry } = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );

        if (expiry && new Date().getTime() > expiry) {
          localStorage.removeItem("admin-token");
          return null;
        }

        return token;
      }
      return null;
    } catch (error) {
      console.error("Error while decrypting token:", error);
      return null;
    }
  },
  setToken: (token: string): boolean => {
    try {
      const expiry = new Date().getTime() + 2 * 60 * 60 * 1000;
      const dataToEncrypt = JSON.stringify({ token, expiry });
      const encryptedData = CryptoJS.AES.encrypt(
        dataToEncrypt,
        SECRET_KEY
      ).toString();
      localStorage.setItem("admin-token", encryptedData);
      return true;
    } catch (error) {
      console.error("Error while encrypting token:", error);
      return false;
    }
  },
  getUser: () => {
    try {
      const encryptedData: any = localStorage.getItem("admin-user");
      if (encryptedData) {
        const { userData, expiry } = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );

        // Check if the user data has expired
        if (expiry && new Date().getTime() > expiry) {
          localStorage.removeItem("admin-user");
          return null;
        }

        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error while decrypting user:", error);
      return null;
    }
  },
  setUser: (user: any): boolean => {
    try {
      const expiry = new Date().getTime() + 2 * 60 * 60 * 1000;
      const dataToEncrypt = JSON.stringify({ userData: user, expiry });
      const encryptedData = CryptoJS.AES.encrypt(
        dataToEncrypt,
        SECRET_KEY
      ).toString();
      localStorage.setItem("admin-user", encryptedData);
      return true;
    } catch (error) {
      console.error("Error while encrypting user:", error);
      return false;
    }
  },

  deleteToken: (): boolean => {
    localStorage.removeItem("admin-token");
    localStorage.removeItem("admin-user");
    return true;
  },
};

export default StorageBox;
