import React, { Suspense } from "react";
import {
  Route,
  /**MemoryRouter */ BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/hoc/PrivateRoute";
import PageLoading from "./components/spinners/PageLoading";
import OutletWithProvider from "./context/OutletWithProvider";

const PageNotFound = React.lazy(() => import("./pages/auth/PageNotFound"));
const Wrapper = React.lazy(() => import("./pages/auth/Wrapper"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Organizations = React.lazy(
  () => import("./pages/Organizations/Organizations")
);

const Extensions = React.lazy(() => import("./pages/Extensions/Extensions"));
const Payments = React.lazy(() => import("./pages/Payments/Payments"));

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Router>
        <Routes>
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/" element={<Wrapper />} />
          <Route path="/signin" element={<Login />} />

          <Route element={<PrivateRoute />}>
            <Route element={<OutletWithProvider />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/organization" element={<Organizations />} />
              <Route path="/extensions" element={<Extensions />} />
              <Route path="/payments" element={<Payments />} />
            </Route>
          </Route>
        </Routes>
      </Router>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
