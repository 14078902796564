import { useState } from "react";
import Context from "./context";
import { IContextGetData } from "../components/interface";

export default function Provider({ children }: { children: React.ReactNode }) {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

  const [dashboardState, setDashboardState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [organizationState, setOrganizationState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [extensionState, setExtensionState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [billingState, setBillingState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  return (
    <Context.Provider
      value={{
        sidebarVisible,
        setSidebarVisible,

        /**Dashboard */
        dashboardState,
        setDashboardState,

        /**Organization */
        organizationState,
        setOrganizationState,

        /**Extensions */
        extensionState,
        setExtensionState,

        /**Billing */
        billingState,
        setBillingState,
      }}
    >
      {children}
    </Context.Provider>
  );
}
